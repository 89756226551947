import { Button } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { motion } from "framer-motion";
import { Container } from "react-bootstrap";
import AnimationTitles from "../components/functions/AnimationTitles";
import bghero from "../images/bg/bg-hero.png";
function HeroContent() {
  return (
    <div className="loading position-relative">
      <Container className="d-flex justify-content-between align-items-center gap-md-5 flex-column flex-md-row mt-3 mt-xl-4 overflow-hidden">
        <motion.div
          initial={{ x: -400 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className={"gradient-text"}> Your Gateway to Seamless Deployment and Cost Optimization </h1>
          <p className="gray-90 mt-3 fs-5">
            Connect to any Cloud, Bare-metal, or Kubernetes servers and infrastructure management. With Nife, it’s all about seamless automation, allowing you to focus on what truly matters—your application.
          </p>
          <Button variant="contained" className="m-0 my-3 px-5 py-2 fw-bold" onClick={() => window.location.href = "https://landing.nife.io/request-demo"}>Request Demo</Button>
          <Button className="m-2 my-3 ml-2 px-5 py-2 fw-bold" onClick={() => window.location.href = "https://launch.nife.io/"}>Try for free</Button>
          <div
            style={{ color: "white" }}
            className="d-none d-md-flex justify-content-between align-items-center my-4"
          >
            <div>
              <h5 className="fw-bold fs-1">200K+</h5>
              <span className="gray-100">Man hours saved</span>
            </div>
            <div>
              <h5 className="fw-bold fs-1">20K+</h5>
              <span className="gray-100">Releases</span>
            </div>
            <div>
              <h5 className="fw-bold fs-1">97%</h5>
              <span className="gray-100">Reduction</span>
            </div>
            <div>
              <h5 className="fw-bold fs-1">10K+</h5>
              <span className="gray-100">Deployments</span>
            </div>
          
          
          </div>
        </motion.div>
        <motion.div
          initial={{ x: 400 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.8 }}
          className="w-100 my-5"
        >
          <div className="cards">
            <img alt="Nife Labs - Oikos homepage demo" src={bghero} style={{ width: "100%" }} />
          
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          style={{ color: "white" }}
          className="d-md-none d-flex justify-content-between align-items-center my-4 features"
        >
             <div>
              <h5 className="fw-bold fs-1">200K+</h5>
              <span className="gray-100">Man hours saved</span>
            </div>
            <div>
              <h5 className="fw-bold fs-1">20K+</h5>
              <span className="gray-100">Releases</span>
            </div>
            <div>
              <h5 className="fw-bold fs-1">97%</h5>
              <span className="gray-100">Reduction</span>
            </div>
            <div>
              <h5 className="fw-bold fs-1">10K+</h5>
              <span className="gray-100">Deployments</span>
            </div>
        </motion.div>
      </Container>
    </div>
  );
}

export default HeroContent;
