import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import AnimationTitles from "../components/functions/AnimationTitles";
import OurValues from './OurValues';


function Careers() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://angel.co/javascripts/embed_jobs.js";
    script.setAttribute("data-startup", "nifelabs");
    script.id = "angellist_embed";
    script.async = true;

    const angelListContainer = document.getElementById('angelListContainer');
    if (angelListContainer) {
      angelListContainer.appendChild(script);
    }

    return () => {
      if (angelListContainer) {
        angelListContainer.innerHTML = ""; 
      }
    };
  }, []);

  return (
    <>
    <div className="about mb-5">
      <Container className="d-flex justify-content-between flex-wrap flex-md-nowrap">
        <motion.div
          initial={{ x: -200 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <AnimationTitles duration={1} title="Amplify Your Professional Journey" className="title" />
          <p className="gray-50 fs-6 mb-2">
          Be an integral part of our exhilarated adventure to build the future of hybrid cloud deployments
          </p>
          <br/>
          <p className="gray-50 fs-6 mb-2">
          our Passion and Expertise will be boasted working on challenges in Start-Up. Things you build here will have tremendous impact on the future of Technology as we know it.
          </p>
          <br/>
          <p className="gray-50 fs-6 mb-5">
          Be a leader, director, challenger & explorer.
          </p>
          <br/>
        </motion.div>
        <motion.div
          initial={{ x: 200 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.8 }}
          className="d-flex flex-column"
        >
          
            <div>
              <img
                src={require("..//images/Img-New-Img-removebg-preview.png")}
                className="p-0 img"
                alt="about us"
              />
            </div>
          
        </motion.div>
      </Container>
    </div>

    <div className="career-page">
      <Helmet>
        <title>Nife - Careers - Join Our Team</title>
        <meta name="description" content="Explore career opportunities at Nife, a global edge application platform company. Join our team and help shape the future of cloud and edge computing." />
        <link rel="canonical" href="https://nife.io/careers" />
      </Helmet>
      
      <Container className="d-flex justify-content-center flex-column text-center">
          <h1 className="title">Open Job Positions</h1>
          <br/>
          
          <p className="gray-50 fs-6 mb-2">
          Be an integral part of our exhilarated adventure to build the future of hybrid cloud deployments
          </p>
        
        <br/>
        <div id="angelListContainer" className="job-listings"></div>
        <br/>
        <p className="gray-50 fs-6 mb-2">
        We are always looking for great talent, if you are interested in other roles than the open positions, hybrid cloud deployments or just want to catch up with us,
          </p>
          <p className="gray-50 fs-6 mb-5">
          Reach out to us at hello@nife.io
          </p>
      </Container>
    </div>
    <OurValues />
    </>
  );
}

export default Careers;
