import React, { useState, useEffect } from 'react';
import { Grid, Typography, IconButton, Slide, Box } from '@mui/material';

const updates = [
    { url: '/', title:'New Features'},
    { url: 'https://launch.nife.io/', title: 'Uptime Monitoring for Your Website' },
    { url: 'https://launch.nife.io/', title: 'Secure Your Github Repo with Code Scanning' },
    { url: 'https://launch.nife.io/', title: 'Track GitHub Performance with DORA Metrics' }
];

export default function NavBarUpdates() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [startAnimation, setStartAnimation] = useState(false);
    const containerRef = React.useRef(null);
    
    // Function to go to the next update
    const nextUpdate = () => {
        setStartAnimation(false);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % updates.length);
            setStartAnimation(true);
        }, 200);  // Adjust this delay to smooth the transition
    };

    // Function to go to the previous update
    const prevUpdate = () => {
        setStartAnimation(false);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + updates.length) % updates.length);
            setStartAnimation(true);
        }, 200);  // Adjust this delay to smooth the transition
    };

    // Automatically cycle through updates every 3 seconds
    useEffect(() => {
        setStartAnimation(true); // Initial animation start after mount
        const interval = setInterval(() => {
            setStartAnimation(false);
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % updates.length);
                setStartAnimation(true);
            }, 200);  // Adjust this delay to smooth the transition
        }, 5000);
        return () => clearInterval(interval);
    }, [updates]);

    return (
        <Box  sx={{overflow: 'hidden' }} container={containerRef.current}>

       
        <Grid container direction="row" justifyContent="space-between" alignItems="center" >
             <Slide in={startAnimation} direction="up" mountOnEnter unmountOnExit timeout={{ enter: 200, exit: 200 }}>
                {updates.length > 0 && <Typography
                    sx={{cursor: "pointer" }}
                    onClick={() => window.location.href = updates[currentIndex].url}
                >
                    {updates[currentIndex].title} →
                </Typography>}
            </Slide>

            <Grid item>
                <IconButton onClick={prevUpdate}>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="15px" width="15px" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>
                </IconButton>
               <span style={{opacity:"0.6",fontSize:"12px"}}>{currentIndex + 1}/{updates.length}</span>
                <IconButton onClick={nextUpdate}>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="15px" width="15px" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>
                </IconButton>
            </Grid>
        </Grid>
        </Box>
    );
};

// Example usage with updates array
