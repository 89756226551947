// MainLayout.js
import { Box, Container } from "@mui/material";
import React from 'react';
import NavBar from '../components/navbar/Navbar';
import NavBarUpdates from '../components/navbar/NewUpdates';
import Footer from '../pages/Footer';
import Subscribe from '../pages/Subscribe';

const MainLayout = ({ children }) => {
    return (
        <div>
          
                <Box sx={{ animation: "announcement 15s ease infinite", background: "linear-gradient(to right, #0d6efd, #0056b3, #4dabf7, #0d6efd9e)", color: "white" }}>
                    <Container>
                        <NavBarUpdates />
                    </Container>
                </Box>
        

            <NavBar />
            <main>{children}</main>
            <Subscribe />
            <Footer />
        </div>
    );
};

export default MainLayout;
