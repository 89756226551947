import React from 'react'
import AboutUs from './AboutUs'
import Awards from './Awards'
import { Helmet } from 'react-helmet-async'

export default function AboutUsPage() {
  return (
    <div>
      <Helmet>
        <title>Nife - About Us - Hybrid cloud platform </title>
        <link rel="canonical" href="https://nife.io/about-us" />
        <meta name="description"
          content="Nife is a global edge application platform company that rapidly empowers enterprises and developers to launch their applications. Connect to any Cloud, Bare-metal, or Kubernetes servers and infrastructure management. It’s all about seamless automation, allowing you to focus on what truly matters." />
      </Helmet>
      <AboutUs />
      {/* <OurStory/> */}
      <Awards />
    </div>
  )
}
