import { CircularProgress } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import fly from "./images/illustration/fly.gif";
import MainLayout from './layouts/MainLayout';
import HomePage from './pages/HomePage';
import Careers from './pages/Careers';
import Partner from './pages/Partner';
import ApplicationPartner from './pages/ApplicationPartner';
import InfraPartners from './pages/InfraPartners';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import AboutUsPage from './pages/AboutUsPage'; 
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: "#0d6efd" },
  },
});


function FunctionRedirect() {
  const location = useLocation();

  useEffect(() => {
    window.location.replace = `https://resource.nife.io${location.pathname}`;
    return () => {

    };
  }, []);
  return (
    <>
  
      <div style={{ textAlign: "center" }}>
        <img src={fly} width={"200px"} />
        <h1 style={{ textAlign: "center", marginBottom: "300px", textTransform: "capitalize" }}><CircularProgress size={30} sx={{ mr: 2 }} />Redirecting to..{location.pathname.replace(/-/g, ' ').replace(/\//g, '')}</h1>
      </div>

    </>)
}

function App() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Router>
          <MainLayout>
         
              <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route exact path="/about-us" element={<AboutUsPage />} />
                <Route exact path="/Careers" element={<Careers />} />
                <Route exact path="/Partner" element={<Partner />} />
                <Route exact path="/ApplicationPartner" element={<ApplicationPartner />} />
                <Route exact path="/InfraPartners" element={<InfraPartners />} />
                
                <Route path="*" element={<FunctionRedirect />} />
              </Routes>
           
          </MainLayout>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
